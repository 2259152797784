<template>
  <div id="app">
    <b-row class="m-3">
      <b-col md="12" class="p-1">
        <div class="row ">
          <b-col md="3" lg="3" sm="3" v-for="sdg in rsData.sdg " v-bind:key="sdg">
            <sdg-icon :img='sdg.code' @loadSDG="loadSDG"></sdg-icon>
          </b-col>
        </div>
      </b-col>
    </b-row>
    <b-row class="m-1">
      <b-col md="12">
        <div v-for="people in rsData.people" v-bind:key="people.code">
          <people-profile :people=people.name @LoadDetail="LoadDetail"></people-profile>
        </div>
      </b-col>
    </b-row>
    <small style="float: right; margin-right: 40px;;">Need correction? Mail to scholar@itb.ac.id </small>
    <a href="http://lppmitb.wbsantosa.online/" class="no-underlines " target="_blank">
      explore in more detail >>
    </a>
    <br>
  </div>
</template>

<script>

import mixpanel from "@/config/mixpanel";
import axios from 'axios'
import PeopleProfile from './components/PeopleProfile.vue'
import SdgIcon from './components/SdgIcon.vue';

export default {
  name: 'App',
  components: {
    SdgIcon, PeopleProfile
  },
  data() {
    return {


      rsData: {
      },

      hits: {},
      url: ''
    }
  },
  watch: {
    url() {
      this.tesLoad();
    }
  },
  mounted() {
    this.url = window.location;
    if (this.url_load == '1') {
      this.url = window.location.href

    } else {
      this.url = 'https://www.itb.ac.id/berita/studi-strategis-dalam-negeri-itb-terima-kunjungan-ppra-66-lemhannas-ri/60760';

    }
    this.loadPage();

  },
  props: {
    possion: {

    },
    url_load: {

    },
    data_ke: {}
  },

  methods: {
    LoadDetail(val, id) {
      mixpanel.track('Widget Profile Detail', {
        'act': 'Load detail Profile',
        'url': this.url,
        'userAccessId': localStorage.getItem('userAccessId'),
        'result': {
          'profile_name': val,
          'profile_id': id
        }
      });

    },
    loadPage() {
      mixpanel.track('access_from Widget', {
        'act': 'Load widget',
        'url': this.url,
        'userAccessId': localStorage.getItem('userAccessId'),
        'result': this.rsData
      });
    },
    async tesLoad() {

      const self = this;
      await axios({
        method: 'POST',
        url: "https://eduqo.com/api/search/identify",
        data: {
          params: {
            url: self.url
          }
        },
        headers: {
          authorization: "rah45ia",
          'Content-Type': 'application/json'
        }
      })
        .then(function (response) {
          self.rsData = response.data.result;
          console.log(response.data)
          this.loadPage();

        }).catch(err => {
          self.pesan = err.message;
        });
    },

  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.no-underlines {
  float: left;
  margin-left: 20px;
  ;
  text-decoration: none;
}
</style>
